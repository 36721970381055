import { Injectable, NgZone } from '@angular/core';
import { FirebaseOptions } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AccountsEngineAngularFirestoreService extends AngularFirestore {}

export function AccountsEngineAngularFirestoreFactory(platformId: Object, zone: NgZone, options: FirebaseOptions) {
  return new AngularFirestore(options, 'accountsengine', false, null, platformId, zone, {}, null, null);
}
