import { Component } from '@angular/core';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { ENVIRONMENT as environment } from '@app/config';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private analyticsSvc: AnalyticsService,
    private platform: Platform,
    private router: Router,
    private translateSvc: TranslateService
  ) {
    this.translateSvc.setDefaultLang(environment.localeId);
    this._setUpAnalyticsRouterEventsLogic();
  }

  private _setUpAnalyticsRouterEventsLogic(): void {
    let routeValue: string, routeConfigPath: string, params: {[param: string]: string}, queryParams: {[param: string]: string};
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) { // this is to identify params and replace their values for their keys
        params = event.snapshot.params;
        queryParams = event.snapshot.queryParams;
        routeConfigPath = event.snapshot.routeConfig.path;
        routeValue = routeConfigPath;
        for (const key in params) {
          routeValue = routeValue.replace(`:${key}`, encodeURIComponent(params[key]));
        }
      }
      if (event instanceof NavigationEnd) {
        const url = routeValue && routeConfigPath ? event.urlAfterRedirects.replace(routeValue, routeConfigPath).split('?')[0] : event.urlAfterRedirects.split('?')[0];
        this.analyticsSvc.setCurrentScreen(url);
        this.analyticsSvc.logEvent('page_view', { ...params, ...queryParams, url });
      }
    });
  }
}
