import { AnalyticsServiceInterface } from '@services/analytics/analytics.service';

export class AnalyticsServiceMock implements AnalyticsServiceInterface {
  logEvent(id: string, data: { [key: string]: string | number | boolean } = {}): Promise<any> {
    return Promise.resolve();
  }
  setCurrentScreen(name: string): Promise<any> {
    return Promise.resolve();
  }
  setUserId(id: string): Promise<any> {
    return Promise.resolve();
  }
  setUserProperties(properties: {[key: string]: any}): Promise<any> {
    return Promise.resolve();
  }
}