import { InjectionToken, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { FirebaseOptions } from '@angular/fire';
import { AccountsEngineAngularFireAuthFactory, AccountsEngineAngularFireAuthService } from './angular-fireauth.service';
import { AccountsEngineAngularFirestoreFactory, AccountsEngineAngularFirestoreService } from './angular-firestore.service';
import { AccountsEngineFirestoreService } from './firestore.service';

export const ACCOUNTS_ENGINE_OPTIONS_TOKEN = new InjectionToken<FirebaseOptions>('accountsengine.angularfire.app.options');

@NgModule({
  providers: [
    AccountsEngineFirestoreService,
    { provide: AccountsEngineAngularFireAuthService, deps: [PLATFORM_ID, NgZone, ACCOUNTS_ENGINE_OPTIONS_TOKEN], useFactory: AccountsEngineAngularFireAuthFactory },
    { provide: AccountsEngineAngularFirestoreService, deps: [PLATFORM_ID, NgZone, ACCOUNTS_ENGINE_OPTIONS_TOKEN], useFactory: AccountsEngineAngularFirestoreFactory }
  ],
})
export class AccountsEngineModule {
  static initialize(options: FirebaseOptions) {
    return {
      ngModule: AccountsEngineModule,
      providers: [
        { provide: ACCOUNTS_ENGINE_OPTIONS_TOKEN, useValue: options }
      ]
    };
  }
}
