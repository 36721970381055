import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { ENVIRONMENT } from '@app/config';
import { AnalyticsServiceMock } from './analytics.service.mock';

export interface AnalyticsServiceInterface {
  logEvent(id: string, data: { [key: string]: string | number | boolean }): Promise<any>;
  setCurrentScreen(name: string): Promise<any>;
  setUserId(id: string): Promise<any>;
  setUserProperties(properties: {[key: string]: any}): Promise<any>
}

@Injectable({
  providedIn: 'root',
  useClass: ENVIRONMENT.useMock ? AnalyticsServiceMock : AnalyticsService,
  deps: [AngularFireAnalytics]

})

export class AnalyticsService implements AnalyticsServiceInterface {

  constructor(
    private analytics: AngularFireAnalytics
  ) {}

  logEvent(id: string, data: { [key: string]: string | number | boolean } = {}): Promise<any> {
    return this.analytics.logEvent(id, data).catch((error) => {
      console.error('Unknown error in AnalyticsService.logEvent', error);
      return Promise.reject(error);
    });
  }
  setCurrentScreen(name: string): Promise<any> {
    return this.analytics.setCurrentScreen(name).catch((error) => {
      console.error('Unknown error in AnalyticsService.setCurrentScreen', error);
      return Promise.reject(error);
    });
  }
  setUserId(id: string): Promise<any> {
    return this.analytics.setUserId(id).catch((error) => {
      console.error('Unknown error in AnalyticsService.setUserId', error);
      return Promise.reject(error);
    });
  }
  setUserProperties(properties: {[key: string]: any}): Promise<any> {
    return this.analytics.setUserProperties(properties).catch((error) => {
      console.error('Unknown error in AnalyticsService.setUserProperties', error);
      return Promise.reject(error);
    });
  }
}
