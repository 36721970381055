import { EnvironmentConfigInterface } from '@app/interfaces/config.interface';

export const ENVIRONMENT: EnvironmentConfigInterface = {
  name: 'prod',
  production: true,
  useMock: false,
  app: {
    packageName: '',
    bundleId: '',
    group: '',
    name: 'Abastible Distribuidor',
    authorEmail: '',
    authorWebsite: 'https://www.abastible.cl/',
    accountManagerKey: ''
  },
  appPlatform: {
    apiUrl: 'https://us-central1-abastible-distributor-app-prod.cloudfunctions.net/',
    firebaseConfig: {
      apiKey: 'AIzaSyD9qCvwycSRVZ_0aljk-v0B-Pwu0HMflI4',
      authDomain: 'abastible-distributor-app-prod.firebaseapp.com',
      databaseURL: 'https://abastible-distributor-app-prod.firebaseio.com',
      projectId: 'abastible-distributor-app-prod',
      storageBucket: 'abastible-distributor-app-prod.appspot.com',
      messagingSenderId: '177728301209',
      appId: '1:177728301209:web:4bb1bb7655e718dc46f008',
      measurementId: 'G-1JFXDXQTTJ'
    }
  },
  accountsEnginePlatform: {
    apiUrl: 'https://us-central1-chek-accounts-engine.cloudfunctions.net/',
    apiUrlShort: 'https://abastible.sp1.cl/',
    firebaseConfig: {
      apiKey: 'AIzaSyAbj2e4AFSs60dKYDTpqgyiQyPOH52p6pM',
      authDomain: 'chek-accounts-engine.firebaseapp.com',
      databaseURL: 'https://chek-accounts-engine.firebaseio.com',
      projectId: 'chek-accounts-engine',
      storageBucket: 'chek-accounts-engine.appspot.com',
      messagingSenderId: '141101030485',
      appId: '1:141101030485:web:dda9522638935cd26408dc'
    }
  },
  commercialTermsUrl: 'https://www.abastible.cl/gas-cilindro/terminos-y-condiciones-de-uso/',
  localeId: 'es-CL',
  paymentsEngineApi: {
    url: 'https://us-central1-heypay-payments-engine.cloudfunctions.net/'
  },
  termsAndConditionsUrl: 'https://www.abastible.cl/gas-cilindro/terminos-y-condiciones-de-uso/'
};
