import { Injectable, NgZone } from '@angular/core';
import { FirebaseOptions } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AccountsEngineAngularFireAuthService extends AngularFireAuth {}

export function AccountsEngineAngularFireAuthFactory(platformId: object, zone: NgZone, options: FirebaseOptions) {
  return new AngularFireAuth(options, 'accountsengine', platformId, zone, null, null, null, null, null, null);
}
