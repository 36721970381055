import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'enrollment',
    loadChildren: './pages/enrollment/enrollment.module#EnrollmentPagesModule'
  },
  {
    path: 'enrolled',
    loadChildren: './pages/enrolled/enrolled.module#EnrolledPagesModule'
  },
  { path: '', redirectTo: 'enrolled', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
